import {
  Component,
  EventEmitter,
  Input, OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatCalendar, MatCalendarCellCssClasses} from '@angular/material/datepicker';
import * as moment from 'moment';

import {CalendarHeaderComponent} from './calendar-header/calendar-header.component';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnChanges {
  @ViewChild('calendar', {static: false}) calendar: MatCalendar<Date>;

  @Input() events: string[] = [];
  @Input() inactiveEvents: string[] = [];
  @Input() firstEventDate;
  @Input() selectedCommunicationType!: number;

  @Output() selectDate: EventEmitter<moment.Moment> = new EventEmitter<moment.Moment>();

  header = CalendarHeaderComponent;

  selectedDate: moment.Moment;

  minDate;
  maxDate;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.events && this.calendar) {
      this.calendar.updateTodaysDate();
    }

    if (changes.inactiveEvents && this.calendar) {
      this.calendar.updateTodaysDate();
    }

    if (changes.firstEventDate?.currentValue) {
      this.selectedDate = this.firstEventDate;
    }
  }
  ngOnInit(): void {
    this.minDate = moment();
    this.maxDate = moment().add(1, 'month');
  }

  onDateChanged(): void {
    this.selectDate.emit(this.selectedDate);
  }

  dateClass = (d: any): MatCalendarCellCssClasses => {
    const date = d.format('DD-MM-YYYY');

    if (this.events.includes(date)) {
      return 'events-date';
    }

    if (this.inactiveEvents.includes(date)) {
      return 'inactive-events-date';
    }

    return '';
  };
}
