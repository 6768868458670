export enum FileTypesEnum {
  PDF = 'application/pdf',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  APNG = 'image/apng',
  GIF = 'image/gif'
}

export enum FileExtensionsEnum {
  PDF = 'pdf',
  JPG = 'jpg',
  JPEG = 'jpeg',
  GIF = 'gif',
  PNG = 'png',
  APNG = 'apng',
  DOC = 'doc',
  DOCX = 'docx',
}

export const docTypes = [FileTypesEnum.DOC, FileTypesEnum.DOCX];

export const imageTypes = [
  FileTypesEnum.JPG,
  FileTypesEnum.JPEG,
  FileTypesEnum.PNG,
  FileTypesEnum.APNG,
  FileTypesEnum.GIF,
];


export const imageFormats = ['.jpg', '.jpeg', '.png', '.apng', '.gif'];
