import {Component, DestroyRef, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

import {WindowSizeService} from '@core/services/window-size.service';
import {WindowSizeConfigInterface} from '@core/interfaces/window-size-config.interface';
import {ProfileService} from '@profile/profile.service';
import {OrdersService} from '../../../../../dashboard/orders/services/orders.service';
import {ClinicSettingsService} from '@core/services/clinic-settings.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {
  @Output() toggleSidenav: EventEmitter<null> = new EventEmitter();
  @Output() closeSidenav: EventEmitter<null> = new EventEmitter();

  pendingOrdersCount$ = this.ordersService.pendingOrdersCount$;
  telegram = '';

  activeClinic$ = this.profileService.activeClinic$;
  windowSizeConfig: WindowSizeConfigInterface;

  constructor(
    private windowSizeService: WindowSizeService,
    private profileService: ProfileService,
    private ordersService: OrdersService,
    private clinicSettings: ClinicSettingsService,
    private destroyRef: DestroyRef
  ) {
    this.handleWindowSizeConfig();
  }

  ngOnInit(): void {
    this.watchTelegram();
  }

  ngOnDestroy(): void {
    this.closeSidenav.emit();
  }

  private handleWindowSizeConfig(): void {
    this.windowSizeService.windowSizeConfig$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((windowSizeConfig: WindowSizeConfigInterface) => this.windowSizeConfig = windowSizeConfig);
  }

  private watchTelegram(): void {
    this.clinicSettings.clinicSettings$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(settings => {
        this.telegram = settings?.telegramLink;
      });
  }
}
