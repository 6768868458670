import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../../environments/environment';
import {getObjectUrlParams} from '@core/utils/filter-converter';
import {
  GetPatientByIdParamsInterface,
  GetPatientsListParamsInterface
} from './interfaces/patients-params.interface';
import {NoteModel, Patient} from '@core/interfaces/patient.interface';
import {JsonaService} from '@core/services/jsona.service';
import {DeserializedJsonInterface} from '@core/interfaces/jsona.interface';

@Injectable({
  providedIn: 'root'
})
export class PatientsService {
  private apiUrlV2 = `${environment.apiUrlV2}/patients`;

  constructor(
    private http: HttpClient,
    private jsonaService: JsonaService
  ) {
  }

  getPatientsList(
    filter: Partial<GetPatientsListParamsInterface>
  ): Observable<DeserializedJsonInterface<Patient[]>> {
    const params = getObjectUrlParams(filter);

    return this.http.get(`${this.apiUrlV2}/list-patients`, {params})
      .pipe(
        map((result) => this.jsonaService.deserialize<Patient[]>(result))
      );
  }

  getAllPatientsList(
    filter: Partial<GetPatientsListParamsInterface>
  ): Observable<DeserializedJsonInterface<Patient[]>> {
    const params = getObjectUrlParams(filter);

    return this.http.get(`${this.apiUrlV2}`, {params})
      .pipe(
        map((result) => this.jsonaService.deserialize<Patient[]>(result))
      );
  }

  byId(id: number, filter: Partial<GetPatientByIdParamsInterface>): Observable<Patient> {
    const params = getObjectUrlParams(filter);

    return this.http.get(`${this.apiUrlV2}/${id}`, {params})
      .pipe(
        map((result) => this.jsonaService.deserialize<Patient>(result).data)
      );
  }

  saveNotes(patientId: number, note: string): Observable<NoteModel> {
    return this.http.post(`${this.apiUrlV2}/save-note`, {patientId, note})
      .pipe(
        map((result) => this.jsonaService.deserialize<NoteModel>(result).data)
      );
  }
}
