import {Injectable} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {urlPattern} from '../../../app.constants';
import {emailPattern} from '@core/constants/patterns.const';
import {currencyList} from '@profile/constants/currency.const';

@Injectable()
export class ProfileFormBuilderService {

  constructor(private fb: FormBuilder) {
  }

  generalInfoForm(): FormGroup {
    return this.fb.group({
      avatar: ['', [Validators.required]],
      avatar_original: [null],
      name: ['', [Validators.required, Validators.maxLength(150)]],
      gender: ['', [Validators.required]],
      birthday: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.maxLength(12)]],
      code: ['', [Validators.required]],
      phoneCountry: [''],
      email: ['', [Validators.required, Validators.maxLength(100), Validators.pattern(emailPattern)]],
      lang: [''],
      currency: [''],
      consultLangs: ['', [Validators.required]],
    });
  }

  additionalStepForm(): FormGroup {
    return this.fb.group({
      mainSpecializationIds: [[], [Validators.required]],
      specializations: [[]],
      specializationDocuments: [[]],
      solveProblems: [[], [Validators.required]],
      experience: ['', [Validators.required]],
      instagram: ['', [Validators.maxLength(1000), Validators.pattern(urlPattern)]],
      fb: ['', [Validators.maxLength(1000), Validators.pattern(urlPattern)]],
      youtube: ['', [Validators.maxLength(1000), Validators.pattern(urlPattern)]],
      tiktok: ['', [Validators.maxLength(1000), Validators.pattern(urlPattern)]],
      description: ['', [Validators.minLength(300), Validators.maxLength(5000)]],
    });
  }

  servicesForm(): FormGroup {
    return this.fb.group({
      services: this.fb.array([])
    });
  }

  createService(service = null): FormGroup {
    return this.fb.group({
      id: [service?.id || ''],
      name: [service?.name || '', [Validators.maxLength(100)]],
      price: [service?.price || '', [Validators.min(1), Validators.max(10000)]],
      implementTime: [service?.implementTime || '', [Validators.maxLength(50)]],
      description: [service?.description || '', [Validators.required, Validators.maxLength(1000)]]
    });
  }

  profileSettingsForm(): FormGroup {
    return this.fb.group({
      currency: [currencyList[0]],
      lang: [''],
    });
  }
}
