import {Component, DestroyRef, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {filter} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-patient-network-error',
  templateUrl: './patient-network-error.component.html',
  styleUrls: ['./patient-network-error.component.scss']
})
export class PatientNetworkErrorComponent implements OnInit {
  chatId: string;

  constructor(
    private route: ActivatedRoute,
    private destroyRef: DestroyRef
  ) {
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        filter((params) => params && params.id),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((params: Params) => (this.chatId = params.id));
  }
}
