import {Component, DestroyRef, Inject, OnInit} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {finalize, mergeMap} from 'rxjs/operators';

import {Patient} from '@core/interfaces/patient.interface';
import {
  GetPatientsListParamsInterface
} from '../../../dashboard/patients/interfaces/patients-params.interface';
import {PatientsService} from '../../../dashboard/patients/patients.service';
import {DeserializedJsonInterface} from '@core/interfaces/jsona.interface';
import {DialoguesService} from '../../../dashboard/dialogues/services/dialogues.service';
import {UserIdTypesEnum} from '@core/enums/user-types.enum';
import {ProfileService} from '@profile/profile.service';
import {ModalInfoService} from '@core/services/modal-info.service';
import {
  DialogueInterface
} from '../../../dashboard/dialogues/interfaces/dialogue/dialogue.interface';
import {of} from 'rxjs';

@Component({
  selector: 'app-modal-find-user',
  templateUrl: './modal-find-user.component.html',
  styleUrls: ['./modal-find-user.component.scss']
})
export class ModalFindUserComponent implements OnInit {
  patients: Patient[] = [];
  isDialogueModal: boolean;
  isHomePage: boolean = false;

  params: Partial<GetPatientsListParamsInterface> = {
    page: 1,
    perPage: 4,
    field: 'updated_at',
    direction: 'DESC'
  };

  dialoguesParams = {
    page: 1,
    perPage: 1,
    without_empty_chat: 0,
    user_type: UserIdTypesEnum.Doctor,
    name: null,
    second_user_type: UserIdTypesEnum.Patient,
    second_user_id: null,
    group: 0
  };

  total = 0;

  firstRequest = true;
  isLoading: boolean;

  constructor(
    private destroyRef: DestroyRef,
    private patientsService: PatientsService,
    private dialoguesService: DialoguesService,
    private modalInfoService: ModalInfoService,
    private profileService: ProfileService,
    private dialogRef: MatDialogRef<ModalFindUserComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
  }

  ngOnInit(): void {
    this.isDialogueModal = this.data.isDialogueModal;
    this.isHomePage = this.data.isHomePage;

    if (this.isDialogueModal || this.isHomePage) {
      this.params.relation_last_consultation = true;
      this.params.with_relation_discussion = true;

      this.getMyPatients();
      return;
    }

    this.getAllPatients();
  }

  onSearch(name: string): void {
    this.isDialogueModal ? this.params.name_all = name : this.params.surname = name;
    this.params.page = 1;
    this.isDialogueModal ? this.getMyPatients(false) : this.getAllPatients(false);
  }

  onPageChange(event: PageEvent): void {
    this.params.page = event.pageIndex + 1;
    this.params.perPage = event.pageSize;

    if (this.isDialogueModal || this.isHomePage) {
      this.getMyPatients(false);
      return;
    }

    this.getAllPatients(false);
  }

  startDialogue(patient: Patient): void {
    this.dialoguesParams.name = this.profileService?.profile$?.value?.name;
    this.dialoguesParams.second_user_id = patient?.id;

    this.dialoguesService.getList(this.dialoguesParams)
      .pipe(
        mergeMap((res: DialogueInterface[]) => {
          if (res.length) {
            return of(res[0]);
          }

          return this.dialoguesService.createNewDialog({
            user_id: patient?.id,
            user_role: UserIdTypesEnum.Patient,
            clinic_id: this.profileService?.profile$?.value?.active_clinic?.id
          });
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: res => this.dialogRef.close({dialogueId: res.id}),
        error: err => {
          this.dialogRef.close(false);
          this.modalInfoService.onError(err)
        }
      })
  }

  invitePatient(patient: Patient): void {
    this.dialogRef.close(patient);
  }

  private getMyPatients(firstRequest = true): void {
    this.isLoading = true;
    this.patientsService.getPatientsList(this.params)
      .pipe(
        finalize(() => this.isLoading = false),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: (result: DeserializedJsonInterface<Patient[]>) => {
          this.patients = result.data;
          this.total = result.meta.total;
          this.firstRequest = firstRequest;
        }
      });
  }

  private getAllPatients(firstRequest = true): void {
    this.patientsService.getAllPatientsList(this.params)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          this.total = res.meta.total;
          this.patients = res.data;
          this.firstRequest = firstRequest;
        }
      });
  }
}
