import {Component, DestroyRef, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {mergeMap} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

import {languagesConst} from '@profile/components/profile/constants/languages.const';
import {ProfileService} from '@profile/profile.service';
import {Doctor} from '@core/interfaces/doctor/doctor.interface';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss']
})
export class LanguageSelectComponent implements OnInit {
  languages = languagesConst;

  lang = new UntypedFormControl('');
  profile: Doctor;

  constructor(
    private profileService: ProfileService,
    private translateService: TranslateService,
    private destroyRef: DestroyRef
  ) {
  }

  ngOnInit(): void {
    this.getProfile();
  }

  manageProfile(event): void {
    this.profileService.patchLanguage(event.language)
      .pipe(
        mergeMap(() => this.profileService.loadProfile()),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private getProfile(): void {
    this.profileService.profile$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((profile: Doctor) => {
        this.profile = profile;
        this.lang.patchValue(this.profile?.lang || this.translateService.getDefaultLang());
      });
  }
}
