import {Component, Inject, inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarLabel, MatSnackBarRef} from '@angular/material/snack-bar';
import {CommonModule} from '@angular/common';

import {TranslateModule} from '@core/pipes/translate/translate.module';

@Component({
  selector: 'app-info-snackbar',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatSnackBarLabel
  ],
  templateUrl: './info-snackbar.component.html',
  styleUrl: './info-snackbar.component.scss'
})
export class InfoSnackbarComponent {
  snackBarRef = inject(MatSnackBarRef);

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: { message: string | string[] }
  ) {
  }

  protected readonly Array = Array;
}
