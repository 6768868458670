import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MatIconButton} from '@angular/material/button';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {MatIcon} from '@angular/material/icon';

import {PatientComponent} from './patient.component';
import {SafeImageModule} from '../../safe-image/safe-image.module';
import {TranslateModule} from '@core/pipes/translate/translate.module';
import {GotoDialogModule} from '@shared-modules/goto-dialog/goto-dialog.module';

@NgModule({
  declarations: [PatientComponent],
  imports: [
    CommonModule,
    SafeImageModule,
    RouterModule,
    TranslateModule,
    GotoDialogModule,
    MatIconButton,
    MatMenuTrigger,
    MatIcon,
    MatMenu,
    MatMenuItem
  ],
  exports: [PatientComponent]
})
export class PatientModule {
}
