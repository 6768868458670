<div class="info" matSnackBarLabel>
  <div class="info_content">
    <p *ngIf="!Array.isArray(data.message); else stringArr"
       [innerHTML]="(data.message | translate)"></p>

    <ng-template #stringArr>
      <p>
        <span *ngFor="let word of data.message">{{(word | translate) + ' '}}</span>
      </p>
    </ng-template>
  </div>
</div>
