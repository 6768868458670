<div class="sidebar" [ngClass]="{minimize: minimize}">
  <a [routerLink]="['/']" class="sidebar-logo">
    <img class="logo"
         [src]="'/assets/images/logo' + (minimize ? '-min' : '') + '.svg'"
         alt="Logo">
  </a>

  <div class="sidebar-menu-wrapper">
    <ul class="sidebar-menu">
      <div>
        <li [routerLinkActive]="['active']" class="sidebar-menu-item">
          <a [routerLink]="['/home' | getHomePath]">
            <mat-icon fontSet="material-symbols-outlined">home</mat-icon>
            <span>{{'Главная' | translate}}</span>
          </a>
        </li>

        <li [routerLinkActive]="['active']" class="sidebar-menu-item">
          <a [routerLink]="['/consultations']">
            <mat-icon fontSet="material-symbols-outlined">videocam</mat-icon>
            <span>{{'Консультации' | translate}}</span>
          </a>
        </li>

        <li [routerLinkActive]="['active']" class="sidebar-menu-item">
          <a
            [routerLink]="['/orders']"
            [class.relative]="minimize"
          >
            <mat-icon fontSet="material-icons-outlined">summarize</mat-icon>
            <span>
              {{'Заказы' | translate}}
            </span>
            <span
              *ngIf="(pendingOrdersCount$ | async)"
              class="orders_icon"
              [matBadge]="(pendingOrdersCount$ | async) > 99 ? '99+' : (pendingOrdersCount$ | async)"
            >
              </span>
          </a>
        </li>

        <li [routerLinkActive]="['active']" class="sidebar-menu-item">
          <a [routerLink]="['/schedule']">
            <mat-icon fontSet="material-symbols-outlined">event</mat-icon>
            <span>{{'Расписание' | translate}}</span>
          </a>
        </li>

        <li [routerLinkActive]="['active']" class="sidebar-menu-item">
          <a [routerLink]="['/my-clients']">
            <mat-icon fontSet="material-icons-outlined">groups</mat-icon>
            <span>{{'Клиенты' | translate}}</span>
          </a>
        </li>

        <li [routerLinkActive]="['active']" class="sidebar-menu-item">
          <a [routerLink]="['/my-services']">
            <mat-icon fontSet="material-symbols-outlined">handshake</mat-icon>
            <span>{{'Услуги' | translate}}</span>
          </a>
        </li>

        <li [routerLinkActive]="['active']" class="sidebar-menu-item">
          <a [routerLink]="['/all-documents']">
            <mat-icon fontSet="material-symbols-outlined">file_copy</mat-icon>
            <span>{{'Документы' | translate}}</span>
          </a>
        </li>

        <li [routerLinkActive]="['active']" class="sidebar-menu-item">
          <a [routerLink]="['/recipes']">
            <mat-icon fontSet="material-symbols-outlined">nutrition</mat-icon>
            <span>{{'Продукти' | translate}}</span>
          </a>
        </li>

<!--        <li [routerLinkActive]="['active']" class="sidebar-menu-item">-->
<!--          <a [routerLink]="['/activities']">-->
<!--            <mat-icon fontSet="material-symbols-outlined">fitness_center</mat-icon>-->
<!--            <span>{{'Бібліотека тренувань' | translate}}</span>-->
<!--          </a>-->
<!--        </li>-->
      </div>

      <div>
        <li class="sidebar-menu-item">
          <a [href]="telegram" target="_blank">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M21 5L2 12.5L9 13.5M21 5L18.5 20L9 13.5M21 5L9 13.5M9 13.5V19L12.249 15.723"
                    stroke="#37474F" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
            <span>{{'Поддержка' | translate}}</span>
          </a>
        </li>

        <li [routerLinkActive]="['active']" class="sidebar-menu-item">
          <a [routerLink]="['/support']">
            <mat-icon fontSet="material-symbols-outlined">contact_support</mat-icon>
            <span>{{'Помощь' | translate}}</span>
          </a>
        </li>
      </div>
    </ul>

    <div class="expand-menu-wrap">
      <mat-icon [ngClass]="{rotate: !minimize}"
                (click)="$event.preventDefault(); toggleSidebar()">
        arrow_forward
      </mat-icon>
    </div>
  </div>

</div>
