import * as moment from 'moment';

import {NotificationTypesEnum} from '../enums/notification-types.enum';
import {
  NotificationInitiator,
  Notification
} from '../../notifications/interfaces/notification.interface';

const prepareNotificationByTypeHandlers = {
  [NotificationTypesEnum.CancelConsultationPatient]: prepareCancelConsultation,
  [NotificationTypesEnum.UnsubscribeWaitingArea]: prepareLeftWR,
  [NotificationTypesEnum.SubscribeWaitingArea]: prepareConnectWR,
  [NotificationTypesEnum.UnsubscribeChat]: prepareLeftChat,
  [NotificationTypesEnum.SubscribeChat]: prepareConnectChat,
  [NotificationTypesEnum.NewDiscussionMessage]: prepareNewDiscussionMessage,
  [NotificationTypesEnum.CreateConsultation]: prepareCreateConsultation,
  [NotificationTypesEnum.OrderCreate]: prepareOrder,
  [NotificationTypesEnum.AnswerQuestionnaire]: prepareAnswerQuestionnaire
};

function defaultNotificationData(item, users) {
  const id = item.attributes.data.initiator_id;
  const type = item.attributes.data.initiator_type;
  const initiatorCandidate = `${id}_${type}`;
  const fullName = `${users[initiatorCandidate].data.attributes.surname || ''}
    ${users[initiatorCandidate].data.attributes.name || ''}
    ${users[initiatorCandidate].data.attributes.middle_name || ''}`;

  const initiator: NotificationInitiator = {
    id,
    type,
    fullName,
    avatar: users[initiatorCandidate].data.attributes.avatar,
    avatarStorage: users[initiatorCandidate].data.attributes.avatar_storage
  };

  return {
    initiator,
    id: item.id,
    createdAt: item.attributes.created_at,
    userId: item.attributes.user_id,
    userType: item.attributes.user_type,
    status: item.attributes.status,
    sendTo: item.attributes.data.send_to,
    type: item.attributes.data.type,
    time: moment.unix(item.attributes.data.time),
  };
}

function prepareConnectWR(itemData) {
  return {
    appointmentId: itemData.appointment_id,
    iconName: 'icon-notification-connect-wr',
    message: 'подключился к',
    action: {
      text: 'комнате ожидания',
      target: `/chat/${itemData.appointment_id}`,
    },
  };
}

function prepareAnswerQuestionnaire(itemData: any) {
  return {
    message: 'пройшов',
    initiator: {
      fullName: itemData.patient_name,
      avatar: itemData.patient_avatar,
      avatarStorage: itemData.patient_storage,
    },
    action: {
      text: 'анкету',
      target: `/questionnaire-results/${itemData.anamnesis_id}`,
    },
  };
}

function prepareOrder(itemData) {
  return {
    action: {
      text: itemData?.count > 1 ? 'Надійшли нові замовлення на послуги' : 'Надійшло нове замовлення на послугу',
      target: '/orders/pending'
    }
  };
}

function prepareLeftWR(itemData) {
  return {
    appointmentId: itemData.appointment_id,
    iconName: 'icon-notification-left-wr',
    message: 'покинул',
    action: {
      text: 'комнату ожидания',
      target: `/chat/${itemData.appointment_id}`,
    },
  };
}

function prepareConnectChat(itemData) {
  return {
    appointmentId: itemData.appointment_id,
    iconName: 'icon-notification-connect-chat',
    message: 'подключился к',
    action: {
      text: 'онлайн чату',
      target: `/chat/${itemData.appointment_id}`,
    },
  };
}

function prepareLeftChat(itemData) {
  return {
    appointmentId: itemData.appointment_id,
    iconName: 'icon-notification-left-chat',
    message: 'покинул',
    action: {
      text: 'онлайн чат',
      target: `/chat/${itemData.appointment_id}`,
    },
  };
}

function prepareCancelConsultation() {
  return {
    iconName: 'icon-notification-cancel-consultation',
    message: 'отменил(а)',
    action: {
      text: 'консультацию',
      target: `/consultations/canceled`,
    },
  };
}

function prepareNewDiscussionMessage(itemData) {
  return {
    iconName: 'icon-message',
    message: 'написал вам новое',
    action: {
      text: 'сообщение',
      target: `/dialogues`,
      query: {dialogueId: itemData.discussion_id}
    },
  };
}

function prepareCreateConsultation(itemData) {
  return {
    iconName: 'icon-notification-consultation-crated',
    message: 'записался к Вам на консультацию',
    action: {
      text: `${moment(itemData.consultation_start_time).format('DD.MM.YYYY HH:mm')}`,
      target: '/consultations/planned',
    },
  };
}

export function prepareNotification(item, users): Notification {
  return {
    ...defaultNotificationData(item, users),
    ...prepareNotificationByTypeHandlers[item.attributes.data.type](item.attributes.data)
  };
}

export function prepareNewNotification(item): Notification {
  const initiator: NotificationInitiator = {
    id: item.initiator_id,
    type: item.initiator_type,
    avatar: item.avatar,
    avatarStorage: item.storage,
    fullName: item.fullname
  };

  return {
    initiator,
    time: moment.unix(item.time),
    type: item.type,
    userType: item.user_type,
    sendTo: item.send_to,
    ...prepareNotificationByTypeHandlers[item.type](item)
  };
}
