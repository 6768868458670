import {DestroyRef, Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

import {buildErrorMessage} from '@core/utils/build-error-message';
import {ModalInfoComponent} from '@shared-modules/modals/modal-info/modal-info.component';
import {ModalInfoInterface} from '@shared-modules/modals/modal-info/modal-info.interface';
import {InfoSnackbarComponent} from '@shared-modules/info-snackbar/info-snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class ModalInfoService {
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    private destroyRef: DestroyRef
  ) {
  }

  onSuccess(params: ModalInfoInterface): void {
    this.openModal(params);
  }

  onError(error: any, title?: string, router?: string): void {
    const params: ModalInfoInterface = {
      message: buildErrorMessage(error),
      type: 'error',
      title,
      router
    };

    this.openModal(params);
  }

  openModal(params: ModalInfoInterface): void {

    const dialogRef = this.dialog.open(ModalInfoComponent, {
      data: {...params},
      panelClass: ['primary-modal', 'modal-sm'],
      autoFocus: false
    });

    if (params.isClose) {
      setTimeout(() => dialogRef.close(false), 3000);
    }

    if (params.router) {
      dialogRef.afterClosed()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.router.navigate([params.router]));
    }
  }

  showSnackBar(message?: string | string[]): void {
    this.snackBar.openFromComponent(InfoSnackbarComponent, {
      data: {
        message,
      },
      duration: 3000,
      panelClass: 'info_snackbar'
    });
  }
}
