import {AppLayoutConfigInterface} from '../interfaces/app-layout-config.interface';

export function prepareAppLayoutConfig(data: Partial<AppLayoutConfigInterface>): AppLayoutConfigInterface {
  return {
    isSidebarHidden: data?.isSidebarHidden,
    isHeaderShortened: data?.isHeaderShortened,
    isNewNotificationsHidden: data?.isNewNotificationsHidden,
    useEmptyHeader: data?.useEmptyHeader,
    headerTitle: data?.headerTitle,
    backRoute: data?.backRoute,
    isLogoClickable: data?.isLogoClickable,
  };
}
