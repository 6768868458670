export enum NotificationTypesEnum {
  CancelConsultationPatient = 'cancel_consultation_patient',
  UnsubscribeWaitingArea = 'unsubscribe_waiting_area',
  SubscribeWaitingArea = 'subscribe_waiting_area',
  UnsubscribeChat = 'unsubscribe_chat',
  SubscribeChat = 'subscribe_chat',
  NewDiscussionMessage = 'new_discussion_message',
  CreateConsultation = 'create_consultation',
  OrderChangeStatus = 'order_service_change_status',
  OrderCreate = 'order_service_create',
  AnswerQuestionnaire = 'answer_questionnaire'
}
