import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../../../environments/environment';
import {ApiNotificationGetResponse} from '../interfaces/notification-response.interface';
import {Notification} from '../interfaces/notification.interface';
import {NotificationDeleteFilter} from '../interfaces/notification-filter.interface';
import {prepareNotification} from '../utils/prepare-notification';
import {ApiListFilter} from '@core/interfaces/list-filter.interface';
import {getObjectUrlParams} from '@core/utils/filter-converter';
import {ApiListResponse} from '@core/interfaces/list-response.interface';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  notificationsUpdated$: Subject<boolean> = new Subject<boolean>();
  newDiscussionNotification$: BehaviorSubject<Notification | null> = new BehaviorSubject<Notification | null>(null);

  private apiUrl = `${environment.apiUrl}/notification`;

  constructor(private http: HttpClient) {
  }

  getNotifications(filter: ApiListFilter): Observable<ApiListResponse<Notification>> {
    const params = getObjectUrlParams(filter);

    return this.http
      .get<ApiNotificationGetResponse>(this.apiUrl, {params})
      .pipe(
        map((result: ApiNotificationGetResponse) => {
          let data = [];

          if (result.data.length) {
            data = result.data.map((item) => prepareNotification(item, result.users));
          }

          return {
            data,
            meta: result.meta
          };
        })
      );
  }

  removeNotifications(params: NotificationDeleteFilter): Observable<Object> {
    return this.http.post(`${this.apiUrl}/delete`, params);
  }
}
