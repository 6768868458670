<div class="header">
  <div class="header_container" [class.wizard]="!showIcons">
    <div class="header_nav_wrapper">
      <app-logo
        [isHeaderShortened]="isLogoClickable  ? false : isHeaderShortened"
        [ngClass]="{'show-logo': showLogo}">
      </app-logo>
    </div>

    <ng-container *ngIf="showIcons">
      <div class="header_info">
        <app-language-select></app-language-select>

        <app-link-dialogues></app-link-dialogues>

        <app-notifications-list [disableClick]="isHeaderShortened"></app-notifications-list>

        <app-profile [disableClick]="isHeaderShortened"></app-profile>

        <div *ngIf="!windowSizeConfig.isSmallDesktop && !isHeaderShortened" class="sidenav-toggle">
          <button mat-icon-button (click)="toggleSidenav.emit()">
            <mat-icon>menu</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
