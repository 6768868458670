import {
  Component, DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import * as moment from 'moment';
import {of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

import {ConsultationsService} from '@consultations/consultations.service';
import {CreateConsultationFilter} from '@consultations/interfaces/consultations-filter.interface';
import {WorkPoints} from '@core/interfaces/work-points/work-points.interface';
import {GetWorkPointsFilter} from '@core/interfaces/work-points/work-points-filter.interface';
import {Doctor} from '@core/interfaces/doctor/doctor.interface';
import {WorkPointsService} from '@core/services/work-points.service';
import {ModalInfoService} from '@core/services/modal-info.service';
import {dateMomentFormat} from '../../../../app.constants';
import {ProfileService} from '@profile/profile.service';
import {ModalInfoInterface} from '@shared-modules/modals/modal-info/modal-info.interface';
import {ModalImagesEnum} from '@core/enums/modal-images.enum';
import {TypeCommunicationEnum} from '@core/enums/type-communication.enum';

@Component({
  selector: 'app-today-event-list',
  templateUrl: './today-event-list.component.html',
  styleUrls: ['./today-event-list.component.scss']
})
export class TodayEventListComponent implements OnChanges, OnInit {
  @Input() selectedDay;
  @Input() isModal = false;
  @Input() doctor: Doctor;
  @Input() patientId: number;
  @Input() rescheduleConsultation: boolean;
  @Input() selectedCommunicationType!: number;
  @Input() hasOfflineConsultation: boolean;

  @Output() selectedWorkPoint = new EventEmitter<WorkPoints>();
  @Output() closeModal = new EventEmitter();

  currentLang: string;

  params: GetWorkPointsFilter = {
    doctorId: null,
    booked: 0,
    status: 1,
    type_communications: []
  };

  events: WorkPoints[] = [];

  selectedEvent: WorkPoints | null;

  isDisabledInvite = false;

  constructor(
    private consultationsService: ConsultationsService,
    private workPointsService: WorkPointsService,
    private modalInfoService: ModalInfoService,
    private profileService: ProfileService,
    private destroyRef: DestroyRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedDay?.currentValue && this.doctor) {
      this.selectedEvent = null;

      this.setParams();
      this.getEventsByDate();
    }
  }

  ngOnInit(): void {
    this.getLang();
  }

  selectEvent(event: WorkPoints): void {
    this.selectedWorkPoint.emit(event);

    if (event.booked) {
      return;
    }

    if (this.selectedEvent && this.selectedEvent.id === event.id) {
      this.selectedEvent = null;
      return;
    }

    this.selectedEvent = event;
  }

  invitePatient(): void {
    if (!this.selectedEvent) {
      return;
    }

    this.isDisabledInvite = true;

    this.createConsultation();
  }

  discardSignUp(): void {
    this.closeModal.emit();
  }

  private createConsultation(): void {
    const params: CreateConsultationFilter = {
      patient_id: this.patientId,
      work_point_id: this.selectedEvent.id,
      type_communication: this.selectedCommunicationType
    };

    this.consultationsService.rescheduleConsultationId$
      .pipe(
        mergeMap(id => {
          if (!id) {
            return of(null);
          }

          this.consultationsService.rescheduleConsultationId$.next(null);
          return this.consultationsService.cancelConsultation(id);
        }),
        mergeMap(() => this.consultationsService.createConsultation(params)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: () => {
          this.closeModal.emit();

          const infoParams: ModalInfoInterface = {
            message: 'Приглашение успешно отправлено!',
            type: 'success',
            title: '',
            router: '/consultations/proposed',
            showBtn: false,
            isClose: true,
            imageType: ModalImagesEnum.invite
          };

          this.modalInfoService.onSuccess(infoParams);
        },
        error: (error) => {
          this.modalInfoService.onError(error);
          this.isDisabledInvite = false;
        },
        complete: () => this.isDisabledInvite = false
      });
  }

  private setParams(): void {
    this.params.doctorId = this.doctor.id;

    if (this.selectedCommunicationType) {
      this.params.type_communications = this.hasOfflineConsultation
        ? [this.selectedCommunicationType, TypeCommunicationEnum.Alltypes]
        : [this.selectedCommunicationType];
    }

    const selectedDate = moment(this.selectedDay).format(dateMomentFormat);
    const currentDate = moment().format(dateMomentFormat);

    if (selectedDate === currentDate) {
      this.params.from = moment().add(10, 'minutes').unix();
      this.params.to = moment().endOf('day').unix();
    } else {
      this.params.from = this.selectedDay.clone().startOf('day').unix();
      this.params.to = this.selectedDay.clone().endOf('day').unix();
    }
  }

  private getEventsByDate(): void {
    this.workPointsService.getWorkPoints(this.params)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result: WorkPoints[]) => this.events = result);
  }

  private getLang(): void {
    this.profileService.currentLang$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(lang => this.currentLang = lang.locale);
  }
}
