import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {FileExtensionsEnum, FileTypesEnum} from '@core/enums/file-types.enum';
import {ModalInfoService} from '@core/services/modal-info.service';
import {map} from 'rxjs/operators';
import {firstValueFrom, Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {NgxImageCompressService} from 'ngx-image-compress';

import {environment} from '../../../environments/environment';
import {ProfileService} from '@profile/profile.service';
import {FilePreviewComponent} from '@entry-components/file-preview/file-preview.component';
import {
  RecommendationTemplate
} from '../../dashboard/all-documents/features/templates/interfaces/recommendation-template.interface';

@Injectable({
  providedIn: 'root'
})
export class FileUploaderService {
  private readonly acceptTypes: string[] = [
    FileTypesEnum.PNG,
    FileTypesEnum.APNG,
    FileTypesEnum.JPEG,
    FileTypesEnum.JPG,
    FileTypesEnum.GIF,
    FileTypesEnum.PDF
  ];
  private apiUrl = `${environment.apiUrl}/file`;

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private imageCompress: NgxImageCompressService,
    private profileService: ProfileService,
    private modalInfoService: ModalInfoService,
  ) {
  }

  static isDocFile(file: any) {
    return file?.name.endsWith('.doc') || file?.name.endsWith('.docx');
  }

  openUri(name: string, storage: string) {
    window.open(
      `${this.apiUrl}/${storage}/${name}?authorization=${this.profileService.getToken().replace('Bearer ', '')}`,
      '_blank',
    );
  }

  buildImageByToken(name: string, storage: string, anamnesisId?: number): Observable<SafeUrl> {
    const params: any = {};

    if (storage !== 'local') {
      params.authorization = this.profileService.getToken().replace('Bearer ', '');
    }

    if (anamnesisId || anamnesisId === 0) {
      params.anamnes = anamnesisId;
    }

    return this.http
      .get(`${this.apiUrl}/${storage}/${name}`, {responseType: 'blob', params})
      .pipe(
        map((result: Blob) => {
          return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(result));
        })
      );
  }

  buildFileUri(name: string, storage: string, originName?: string) {
    const params: any = {};

    if (storage !== 'local') {
      params.authorization = this.profileService.getToken().replace('Bearer ', '');
    }

    return firstValueFrom(
      this.http
        .get(`${this.apiUrl}/${storage}/${name}`, {responseType: 'blob', params})
    );
  }

  downloadFile(file: Blob, name?: string) {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(file);
    a.download = name || 'sample.csv';
    a.click();
  };


  buildPdfByToken(fileName, storage = 'local', anamnesisId?: number) {
    const params: any = {};

    if (storage !== 'local') {
      params.authorization = this.profileService.getToken().replace('Bearer ', '');
    }

    if (anamnesisId) {
      params.anamnes = anamnesisId;
    }

    return this.http
      .get(`${this.apiUrl}/${storage}/${fileName}`, {responseType: 'blob', params})
      .pipe(
        map((res) => {
          return {
            secureUrl: this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(res)),
            url: URL.createObjectURL(res),
            file: res
          };
        })
      );
  }

  dataURItoBlob(dataURI, fileType: string): Blob {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([int8Array], {type: fileType});
  }

  async compressFile(image, fileName, fileType): Promise<File> {
    const orientation = -1;
    return await this.imageCompress
      .compressFile(image, orientation, 100, 100, 1024, 1024)
      .then(
        result => {
          const imageBlob = this.dataURItoBlob(result.split(',')[1], fileType);
          return new File([imageBlob], fileName, {type: fileType});
        });
  }

  previewFile(file): void {
    let data;

    if (file.id) {
      data = {
        type: (file.file_name || file.name).split('.')[1],
        fileName: file.file_name || file.name,
        storage: file.storage
      };
    } else {
      data = {
        localUrl: file?.url,
        type: file?.file?.type
      };
    }

    this.dialog.open(FilePreviewComponent, {
      data,
      panelClass: ['primary-modal', 'modal-xl'],
      autoFocus: false
    });
  }

  previewTemplate(template: RecommendationTemplate): void {
    this.dialog.open(FilePreviewComponent, {
      data: {
        type: (template.image).split('.')[1],
        fileName: template.image,
        storage: template.imageStorage
      },
      panelClass: ['primary-modal', 'modal-xl'],
      autoFocus: false
    });
  }

  validateFileOnSize(size: number, limit: number = 5): boolean {
    const mb = size / 1024 / 1000;
    const result = mb > limit;

    if (result) {
      this.modalInfoService.onError({
        customClientError: true,
        customClientErrorMessage: `The size of the attached file can be no more than ${limit} MB`
      });
    }

    return result;
  }

  validateFileFormat(file: File, acceptTypes = this.acceptTypes): boolean {
    const fileType = this.getFileType(file);

    if (!acceptTypes.includes(fileType)) {
      this.modalInfoService.onError('Invalid format');

      return true;
    }

    return false;
  }

  getFileType(file: File): string {
    const parts = file.name.split('.');
    const fileExtension = parts[parts.length - 1].toLowerCase();
    let type = 'unknown';

    switch (fileExtension) {
      case FileExtensionsEnum.JPG:
      case FileExtensionsEnum.JPEG:
      case FileExtensionsEnum.PNG:
      case FileExtensionsEnum.APNG:
      case FileExtensionsEnum.GIF:
        type = `image/${fileExtension}`;
        break;

      case FileExtensionsEnum.PDF:
        type = `application/${fileExtension}`;
        break;
    }

    return type;
  }

  async openFile(file: any) {
    let url: string;

    if (file.storage || file.fileStorage) {
      if (FileUploaderService.isDocFile(file)) {
        return this.downloadFileByUrl(`${file.storage || file.fileStorage}/${file.file || file.name}?authorization=${this.profileService.getToken().replace('Bearer ', '')}`, file.name);
      }
      url = await this.getFilesUrl(file.storage || file.fileStorage, file.hashName || file.file || file.name);
    } else {
      url = URL.createObjectURL(file.file);
    }

    if (!url) {
      return;
    }

    this.openInNewTab(url);
  }

  getFilesUrl(storage: string, file: string): Promise<string> {
    return firstValueFrom(
      this.http
        .get(`${this.apiUrl}/${storage}/${file}?authorization=${this.profileService.getToken().replace('Bearer ', '')}`, {responseType: 'blob'})
    )
      .then((res: Blob) => URL.createObjectURL(res), () => '');
  }

  downloadFileByUrl(file: string, name: string = '') {

    return firstValueFrom(
      this.http
        .get(`${this.apiUrl}/${file}`, {responseType: 'blob'})
    ).then((res: Blob) => {
        const a = document.createElement('a');

        if (FileUploaderService.isDocFile({name})) {
          res = res.slice(
            0, res.size,
            name.endsWith('.docx') ?
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document' :
              'application/msword'
          );
        }

        a.href = URL.createObjectURL(res);
        a.download = name || 'recommendation.docx';
        a.click();
      },
      (err) => err
    );
  }

  private openInNewTab(url: string): void {
    window.open(url, '_blank');
  }

}
