import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, firstValueFrom, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../../../environments/environment';
import {DialogueInterface} from '../interfaces/dialogue/dialogue.interface';
import {getObjectUrlParams} from '../../../core/utils/filter-converter';
import {
  CreateDialogInterface,
  GetDialogueParamsInterface,
  GetDialoguesListParamsInterface
} from '../interfaces/dialogue/dialogue-params.interface';
import {Patient} from '../../../core/interfaces/patient.interface';
import {JsonaService} from '../../../core/services/jsona.service';
import {
  ApiJsonBodyInterface,
  DeserializedJsonInterface
} from '../../../core/interfaces/jsona.interface';

@Injectable({
  providedIn: 'root'
})
export class DialoguesService {
  private apiUrlV2 = `${environment.apiUrlV2}/discussion`;

  constructor(
    private http: HttpClient,
    private jsonaService: JsonaService
  ) {
  }

  getDialoguesList(filter: Partial<GetDialoguesListParamsInterface>): Promise<DialogueInterface[]> {
    const params = getObjectUrlParams(filter);

    return firstValueFrom(this.http.get(this.apiUrlV2, {params})
      .pipe(
        map((result: DeserializedJsonInterface<DialogueInterface[]>) => (
          this.jsonaService.deserialize<DialogueInterface[]>(result).data
        ))
      )
    );
  }

  getList(filter: Partial<GetDialoguesListParamsInterface>): Observable<DialogueInterface[]> {
    const params = getObjectUrlParams(filter);

    return this.http.get(this.apiUrlV2, {params})
      .pipe(
        map((result: DeserializedJsonInterface<DialogueInterface[]>) => (
          this.jsonaService.deserialize<DialogueInterface[]>(result).data
        ))
      );
  }

  createNewDialog(params: CreateDialogInterface): Observable<any> {
    return this.http.post(`${this.apiUrlV2}`, params)
      .pipe(map(res => this.jsonaService.deserialize(res).data));
  }

  createGroup(data: FormData): Promise<DialogueInterface> {
    return firstValueFrom(
      this.http.post(`${this.apiUrlV2}/group`, data)
        .pipe(
          map(res => this.jsonaService.deserialize<DialogueInterface>(res).data)
        )
    )
  }

  editDialogue(data: any, id: number): Promise<any> {
    return firstValueFrom(
      this.http.patch(`${this.apiUrlV2}/${id}`, data)
    )
  }

  clearDialogueHistory(dialogueId: number): Promise<void> {
    return firstValueFrom(
      this.http.delete<void>(`${this.apiUrlV2}/delete-history/${dialogueId}`)
    )
  }

  deleteDialogue(dialogueId: number): Promise<void> {
    return firstValueFrom(
      this.http.delete<void>(`${this.apiUrlV2}/${dialogueId}`)
    )
  }

  manageUserInDiscussion(discussionId: number, userId: number, isAsigned: boolean): Promise<void> {
    return isAsigned
      ? this.unAssignUser(discussionId, userId)
      : this.assignUser(discussionId, userId);
  }

  assignUser(discussionId: number, userId: number): Promise<void> {
    return firstValueFrom(
      this.http.post<void>(`${this.apiUrlV2}-users/${discussionId}`, {patientId: userId})
    )
  }

  unAssignUser(discussionId: number, userId: number): Promise<void> {
    return firstValueFrom(
      this.http.delete<void>(`${this.apiUrlV2}-users/${discussionId}/${userId}`)
    )
  }

  getDialogueById(id: number): Promise<DialogueInterface> {
    const filter = {
      with_relation_doctor: true,
      with_relation_patient: true,
      with_relation_discussion_message: true,
      with_relation_count_not_read_message: true
    };

    const params = getObjectUrlParams(filter);

    return firstValueFrom(
      this.http.get(`${this.apiUrlV2}/${id}`, {params})
        .pipe(
          map(res => this.jsonaService.deserialize<DialogueInterface>(res).data)
        )
    )
  }
}
